import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CreateTraining.css';

const CreateTraining = () => {
  const [training, setTraining] = useState({
    type: '',
    description: '',
    videoUrl: '',
    pdfUrl: '',
    county: '',
    subcounty: '',
    location: '',
    speakers: '',
    cropTeaching: '',
    date: '',
    time: '',
    status: 'Active'
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTraining(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post('https://rafikipaypayfarmersystem.co.ke/api/trainings/create', training, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      console.log('Training created successfully:', response.data);
      navigate('/admin-dashboard');
    } catch (error) {
      console.error('Error creating training:', error);
    }
  };

  const handleBack = () => {
    navigate('/admin-dashboard');
  };

  return (
    <div className="create-training-container">
      <h1>Upcoming Trainings</h1>
      <form onSubmit={handleSubmit} className="create-training-form">
        <div className="form-group">
          <label htmlFor="type">Type <span className="required">*</span></label>
          <select name="type" id="type" value={training.type} onChange={handleChange} required>
            <option value="">Select Type</option>
            <option value="Video">Video</option>
            <option value="PDF">PDF</option>
            <option value="Physical">Physical</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="description">Description <span className="required">*</span></label>
          <textarea name="description" id="description" value={training.description} onChange={handleChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="videoUrl">Video URL</label>
          <input type="text" name="videoUrl" id="videoUrl" value={training.videoUrl} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label htmlFor="pdfUrl">PDF URL</label>
          <input type="text" name="pdfUrl" id="pdfUrl" value={training.pdfUrl} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label htmlFor="county">County</label>
          <input type="text" name="county" id="county" value={training.county} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label htmlFor="subcounty">Subcounty</label>
          <input type="text" name="subcounty" id="subcounty" value={training.subcounty} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label htmlFor="location">Location</label>
          <input type="text" name="location" id="location" value={training.location} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label htmlFor="speakers">Speakers</label>
          <input type="text" name="speakers" id="speakers" value={training.speakers} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label htmlFor="cropTeaching">Crop Teaching</label>
          <input type="text" name="cropTeaching" id="cropTeaching" value={training.cropTeaching} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label htmlFor="date">Date</label>
          <input type="date" name="date" id="date" value={training.date} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label htmlFor="time">Time</label>
          <input type="text" name="time" id="time" value={training.time} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label htmlFor="status">Status <span className="required">*</span></label>
          <select name="status" id="status" value={training.status} onChange={handleChange}>
            <option value="Active">Active</option>
            <option value="Suspended">Suspended</option>
          </select>
        </div>

        <button type="submit" className="submit-button">Create Training</button>
        <button type="button" className="back-button" onClick={handleBack}>Back to Admin Dashboard</button>
      </form>
    </div>
  );
};

export default CreateTraining;