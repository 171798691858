import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// Import your components
import FarmerDashboard from './components/FarmerDashboard';
import FarmerProfile from './components/FarmerProfile';
import Login from './components/Login';
import Monitor from './components/Monitor';
import Register from './components/Register';
import Training from './components/Training';
import CreateMonitor from './components/CreateMonitor';
import History from './components/History';
import AgronomistRegister from './components/AgronomistRegister';
import AgronomistLogin from './components/AgronomistLogin';
import AgronomistDashboard from './components/AgronomistDashboard';
import CreateFarmer from './components/CreateFarmer';
import AgronomistProfile from './components/AgronomistProfile';
import AdminRegister from './components/AdminRegister';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';
import CreateAgronomist from './components/CreateAgronomist';
import SuspendAgronomist from './components/SuspendAgronomist';
import DeleteAgronomist from './components/DeleteAgronomist';
import AdminProfile from './components/AdminProfile';
import CreateTraining from './components/CreateTraining';
import ViewFarmers from './components/ViewFarmers';
import AdminDiseases from './components/AdminDiseases';
import ViewAgronomists from './components/ViewAgronomists';

function App() {
    return (
        <Router>
            <Routes>
                {/* Redirect root path to /home */}
                <Route path="/" element={<Navigate to="/home" />} />

                {/* Default Route - Farmer Dashboard */}
                <Route path="/home" element={<FarmerDashboard />} />

                {/* Farmer Routes */}
                <Route path="/profile/:id" element={<FarmerProfile />} />
                <Route path="/login" element={<Login />} />
                <Route path="/create-monitor" element={<CreateMonitor />} />
                <Route path="/monitor" element={<Monitor />} />
                <Route path="/register" element={<Register />} />
                <Route path="/training" element={<Training />} />
                <Route path="/history" element={<History />} />

                {/* Agronomist Routes */}
                <Route path="/register-agronomist" element={<AgronomistRegister />} />
                <Route path="/login-agronomist" element={<AgronomistLogin />} />
                <Route path="/agronomist-dashboard" element={<AgronomistDashboard />} />
                <Route path="/create-farmer" element={<CreateFarmer />} />
                <Route path="/agronomist-profile" element={<AgronomistProfile />} />

                {/* Admin Routes */}
                <Route path="/register-admin" element={<AdminRegister />} />
                <Route path="/login-admin" element={<AdminLogin />} />
                <Route path="/admin-dashboard" element={<AdminDashboard />} />
                <Route path="/create-agronomist" element={<CreateAgronomist />} />
                <Route path="/suspend-agronomist" element={<SuspendAgronomist />} />
                <Route path="/delete-agronomist" element={<DeleteAgronomist />} />
                <Route path="/admin-profile" element={<AdminProfile />} />
                <Route path="/create-training" element={<CreateTraining />} />
                <Route path="/view-farmers" element={<ViewFarmers />} />
                <Route path="/view-agronomists" element={<ViewAgronomists />} />
                <Route path="/admin-diseases" element={<AdminDiseases />} />

                {/* Catch-All Route */}
                <Route path="*" element={<p>404 Not Found</p>} />
            </Routes>
        </Router>
    );
}

export default App;