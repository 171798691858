import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ViewAgronomists.css';

function ViewAgronomists() {
  const [agronomists, setAgronomists] = useState([]);
  const [selectedAgronomist, setSelectedAgronomist] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAgronomists = async () => {
      try {
        const response = await axios.get('https://rafikipaypayfarmersystem.co.ke/api/all-agronomists', {
          withCredentials: true,
        });
        setAgronomists(response.data);
      } catch (error) {
        setError(error.response?.data?.error || 'Error fetching agronomists');
      }
    };
  
    fetchAgronomists();
  }, []);

  const handleCardClick = (agronomist) => {
    setSelectedAgronomist(agronomist);
  };

  const handleClosePopup = () => {
    setSelectedAgronomist(null);
  };

  const handleSuspend = async (id) => {
    try {
      await axios.put(`https://rafikipaypayfarmersystem.co.ke/api/suspend-agronomist/${id}`, {}, { withCredentials: true });
      setAgronomists(prevAgronomists => prevAgronomists.map(ag => ag.id === id ? { ...ag, suspended: true } : ag));
      handleClosePopup();
    } catch (error) {
      setError(error.response?.data?.error || 'Error suspending agronomist');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://rafikipaypayfarmersystem.co.ke/api/delete-agronomist/${id}`, { withCredentials: true });
      setAgronomists(prevAgronomists => prevAgronomists.filter(ag => ag.id !== id));
      handleClosePopup();
    } catch (error) {
      setError(error.response?.data?.error || 'Error deleting agronomist');
    }
  };

  return (
    <div className="view-agronomists-container">
      <header className="view-agronomists-header">
        <h1>Agronomists</h1>
      </header>

      {error && <p className="error-message">Error: {error}</p>}

      <div className="agronomists-list">
        {agronomists.length > 0 ? (
          agronomists.map(agronomist => (
            <div
              key={agronomist.id}
              className="agronomist-card"
              onClick={() => handleCardClick(agronomist)}
            >
              <div className="card-header">
                <h2>{agronomist.name}</h2>
                <span className="id number">{agronomist.idNumber} ID Number</span>
              </div>
              <div className="card-body">
                <p>Email: {agronomist.email}</p>
                <p>Contact: {agronomist.contact}</p>
              </div>
            </div>
          ))
        ) : (
          <p>No agronomists available.</p>
        )}
      </div>

      {selectedAgronomist && (
        <div className="popup">
          <div className="popup-content">
            <h2>{selectedAgronomist.name}</h2>
            <p>ID Number: {selectedAgronomist.idNumber}</p>
            <p>Email: {selectedAgronomist.email}</p>
            <p>Contact: {selectedAgronomist.contact}</p>
            <p>Status: {selectedAgronomist.suspended ? 'Suspended' : 'Active'}</p>

            <button onClick={() => handleSuspend(selectedAgronomist.id)}>
              {selectedAgronomist.suspended ? 'Unsuspend' : 'Suspend'}
            </button>
            <button onClick={() => handleDelete(selectedAgronomist.id)}>Delete</button>
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewAgronomists;