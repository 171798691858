import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './ViewFarmers.css';

function ViewFarmers() {
  const [farmers, setFarmers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFarmers = async () => {
      try {
        const response = await axios.get('https://rafikipaypayfarmersystem.co.ke/api/farmers/', {
          withCredentials: true,
        });
        console.log('Farmers response:', response.data); // Log the response data
        setFarmers(response.data);
      } catch (error) {
        console.error('Error fetching farmers:', error.response?.data?.error || error.message);
        setError(error.response?.data?.error || 'Error fetching farmers');
      }
    };

    fetchFarmers();
  }, []);

  return (
    <div className="view-farmers-container">
      <header className="view-farmers-header">
        <h1>Farmers</h1>
        {/* Display the count of farmers */}
        <p>Total Farmers: {farmers.length}</p>
      </header>

      {error && <p className="error-message">Error: {error}</p>}

      <div className="farmers-list">
        {farmers.length > 0 ? (
          farmers.map(farmer => (
            <div key={farmer.id} className="farmer-card">
              <div className="card-header">
                <h2>{farmer.name}</h2>
                <span className="id-number">ID Number: {farmer.idnumber}</span>
              </div>
              <div className="card-body">
                <p>Location: {farmer.location}</p>
                <p>Contact: {farmer.contact}</p>
              </div>
            </div>
          ))
        ) : (
          <p>No farmers available.</p>
        )}
      </div>

      <footer className="view-farmers-footer">
        <Link to="/admin-dashboard" className="footer-icon">My ads</Link>
        <Link to="/create-farmer" className="footer-icon">New members</Link>
        <Link to="/create-training" className="footer-icon">New Trainings</Link>
        <Link to="/admin-profile" className="footer-icon">More</Link>
      </footer>
    </div>
  );
}

export default ViewFarmers;