import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Removed useNavigate
import axios from 'axios';
import './SuspendAgronomist.css';

const SuspendAgronomist = () => {
  const [id, setId] = useState('');
  const [suspendedAgronomists, setSuspendedAgronomists] = useState([]);

  useEffect(() => {
    fetchSuspendedAgronomists();
  }, []);

  const fetchSuspendedAgronomists = async () => {
    try {
      const response = await axios.get('https://rafikipaypayfarmersystem.co.ke/api/admins/suspended-agronomists', { withCredentials: true });
      console.log('Suspended Agronomists:', response.data); // For debugging
      setSuspendedAgronomists(response.data);
    } catch (error) {
      console.error('Error fetching suspended agronomists:', error);
    }
  };

  const handleChange = (e) => {
    setId(e.target.value);
  };

  const handleSuspend = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://rafikipaypayfarmersystem.co.ke/api/admins/suspend-agronomist/${id}`, {}, { withCredentials: true });
      alert('Agronomist suspended successfully');
      setId('');
      fetchSuspendedAgronomists();
    } catch (error) {
      console.error('Error suspending agronomist:', error);
    }
  };

  const handleUnsuspend = async (agronomistId) => {
    try {
      await axios.put(`https://rafikipaypayfarmersystem.co.ke/api/admins/unsuspend-agronomist/${agronomistId}`, {}, { withCredentials: true });
      alert('Agronomist unsuspended successfully');
      fetchSuspendedAgronomists();
    } catch (error) {
      console.error('Error unsuspending agronomist:', error);
    }
  };

  const handleDelete = async (agronomistId) => {
    try {
      await axios.delete(`https://rafikipaypayfarmersystem.co.ke/api/admins/delete-agronomist/${agronomistId}`, { withCredentials: true });
      alert('Agronomist deleted successfully');
      fetchSuspendedAgronomists();
    } catch (error) {
      console.error('Error deleting agronomist:', error);
    }
  };

  return (
    <div className="container">
      <form onSubmit={handleSuspend}>
        <input type="text" value={id} onChange={handleChange} placeholder="Agronomist ID" />
        <button type="submit">Suspend Agronomist</button>
      </form>
      <h2>Suspended Agronomists</h2>
      <ul>
        {suspendedAgronomists.map((agronomist) => (
          <li key={agronomist.id}>
            <span>{agronomist.name}</span> 
            <button onClick={() => handleUnsuspend(agronomist.id)}>Unsuspend</button>
            <button onClick={() => handleDelete(agronomist.id)}>Delete</button>
          </li>
        ))}
      </ul>
      <footer className="dashboard-footer">
        <Link to="/admin-dashboard" className="footer-icon">🏠</Link>
        <Link to="/monitor" className="footer-icon">📊</Link>
        <Link to="/training" className="footer-icon">📚</Link>
        <Link to="/admin-profile" className="footer-icon">👤</Link>
        <Link to="/login" className="login-icon">🔓</Link>
      </footer>
    </div>
  );
};

export default SuspendAgronomist;