import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; // Import Link for routing
import './Login.css'; // Import the CSS file for styling
import rafikiLogo from '../assets/rafiki-logo-1.jpeg'; // Import logo image

function FarmerLogin() {
  const [idNumber, setIdNumber] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // useNavigate instead of useHistory

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://rafikipaypayfarmersystem.co.ke/api/farmers/login', { idNumber, password }, { withCredentials: true });
      console.log('Login Response:', response.data);
      localStorage.setItem('farmerId', response.data.farmer.id); // Store the farmer's internal ID
      localStorage.setItem('farmer', JSON.stringify(response.data.farmer)); // Save full farmer data
      localStorage.setItem('token', response.data.token); // Store the token
      navigate(`/profile/${response.data.farmer.id}`); // Redirect to the profile page using the correct ID
    } catch (error) {
      setError('Invalid credentials. Please try again.');
      console.error('Login failed:', error.response?.data?.error || error.message);
    }
  };

  const handleRedirect = (path) => {
    navigate(path);
  };

  return (
    <div className="login-container">
      <h2>Welcome to RafikiPay</h2>
      <img src={rafikiLogo} alt="RafikiPay Logo" className="logo" />
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="ID Number"
          value={idNumber}
          onChange={(e) => setIdNumber(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {error && <p className="error-message">{error}</p>}
        <button type="submit">Login</button>
      </form>
      <div className="register-link">
        Don't have an account? <Link to="/register">Register now.</Link>
      </div>
      <div className="role-buttons">
        <button onClick={() => handleRedirect('/login-agronomist')}>Agronomist Login</button>
        <button onClick={() => handleRedirect('/login-admin')}>Admin Login</button>
      </div>
      <footer className="dashboard-footer">
        <Link to="/home" className="footer-icon">🔓</Link>
        <Link to="/monitor" className="footer-icon">📊</Link>
        <Link to="/training" className="footer-icon">📚</Link>
        <Link to="/login" className="login-icon">👤</Link>
      </footer>
    </div>
  );
}

export default FarmerLogin;