import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AgronomistProfile.css';

const AgronomistProfile = () => {
  const [agronomist, setAgronomist] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get('https://rafikipaypayfarmersystem.co.ke/api/agronomists/profile', { withCredentials: true });
        setAgronomist(response.data);
      } catch (error) {
        console.error('Error fetching agronomist profile:', error);
        if (error.response && error.response.status === 401) {
          navigate('/agronomist-login');
        }
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await axios.post('https://rafikipaypayfarmersystem.co.ke/api/agronomists/logout', {}, { withCredentials: true });
      navigate('/agronomist-login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  if (!agronomist) {
    return <div>Loading...</div>;
  }

  return (
    <div className="agronomist-profile-container">
      <header className="agronomist-profile-header">
        <img src="agronomist-profile-image-url" alt="Agronomist" className="profile-image" />
        <h2>Agronomist Profile</h2>
      </header>
      <div className="agronomist-profile-details">
        <p><strong>Name:</strong> {agronomist.name}</p>
        <p><strong>Email:</strong> {agronomist.email}</p>
        <p><strong>ID Number:</strong> {agronomist.idNumber}</p>
      </div>
      <button className="logout-button" onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default AgronomistProfile;