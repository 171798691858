import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './Monitor.css';

function Monitor() {
  const [monitoringReports, setMonitoringReports] = useState([]);
  const [farmerIdNumber, setFarmerIdNumber] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const storedFarmerId = localStorage.getItem('farmerIdNumber');
    if (storedFarmerId) {
      fetchMonitoringReports(storedFarmerId);
    }
  }, []);

  const fetchMonitoringReports = async (farmerId) => {
    setLoading(true);
    try {
      const response = await axios.get('https://rafikipaypayfarmersystem.co.ke/api/monitors', {
        params: { farmerIdNumber: farmerId },
      });

      setMonitoringReports(response.data);
      setError(null);
    } catch (error) {
      setError('An error occurred while fetching reports.');
      setMonitoringReports([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem('farmerIdNumber', farmerIdNumber);
    fetchMonitoringReports(farmerIdNumber);
  };

  const handleCreateNewMonitor = () => {
    navigate('/create-monitor');
  };

  return (
    <div className="monitor-container">
      <header className="monitor-header">
        <h1>Hi! 👋</h1>
        <p>Enter the Farmer ID Number to view your monitoring reports.</p>
      </header>

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={farmerIdNumber}
          onChange={(e) => setFarmerIdNumber(e.target.value)}
          placeholder="Enter Farmer ID Number"
          required
        />
        <button type="submit">Get Monitoring Reports</button>
      </form>

      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}

      <button className="create-monitor-btn" onClick={handleCreateNewMonitor}>
        Fill in your plant progress
      </button>

      {monitoringReports.length > 0 && (
        <div className="monitoring-reports">
          {monitoringReports.map((report) => (
            <div key={report.id} className="monitoring-report-card">
              <h2>{report.cropName}</h2>
              <p>{report.description}</p>
              <p><strong>Stage:</strong> {report.stage}</p>
              <p><strong>Land Size:</strong> {report.landSize} acres</p>
              <div className="report-images">
                {report.pictures && Array.isArray(report.pictures) && report.pictures.length > 0 ? (
                  report.pictures.map((pic, index) => (
                    <img key={index} src={`https://rafikipaypayfarmersystem.co.ke/uploads/${pic}`} alt={`Monitor ${index}`} />
                  ))
                ) : (
                  <p>No pictures available</p>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="monitor-featured">
        <h2>Featured Products</h2>
        <div className="featured-products">
          <div className="product-card">
            <img src="soya.jpeg" alt="Rice Seeds" />
          </div>
          <div className="product-card">
            <img src="Sunflower.jpg" alt="Lime" />
          </div>
          <div className="product-card">
            <img src="tractor.png" alt="Tractor" />
          </div>
          <div className="product-card">
            <img src="peas.png" alt="Peas Seeds" />
          </div>
        </div>
      </div>

      <footer className="dashboard-footer">
        <Link to="/" className="footer-icon">🏠</Link>
        <Link to="/create-monitor" className="footer-icon">📊</Link>
        <Link to="/login" className="footer-icon">👤</Link>
        <Link to="/login" className="login-icon">🔒</Link>
      </footer>

      <div className="monitor-banner">
        <div className="banner-content">
          <h2>Free Consultation</h2>
          <p>Get free support from our customer service</p>
          <a href="tel:0794457203" className="call-now-btn">Call Now</a>
        </div>
      </div>
    </div>
  );
}

export default Monitor;