import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './History.css';

function History() {
  const [monitoringReports, setMonitoringReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchMonitoringReports = async (farmerId) => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://rafikipaypayfarmersystem.co.ke/api/monitors', {
          params: { farmerIdNumber: farmerId },
          headers: { 'Authorization': `Bearer ${token}` },
          withCredentials: true
        });
        setMonitoringReports(response.data);
        setError(null);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Unauthorized. Please log in again.');
          // Optionally redirect to login page
        } else {
          setError(error.response?.data?.error || 'An error occurred while fetching reports.');
        }
        setMonitoringReports([]);
      } finally {
        setLoading(false);
      }
    };
    fetchMonitoringReports();
  }, []);

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://rafikipaypayfarmersystem.co.ke/api/monitors/${id}`, {
        headers: { 'Authorization': `Bearer ${token}` },
        withCredentials: true,
      });
      setMonitoringReports((prevReports) => prevReports.filter((report) => report.id !== id));
    } catch (error) {
      setError(error.response?.data?.error || error.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="history-container">
      <header className="history-header">
        <h1>Monitoring History</h1>
      </header>
      <div className="monitoring-reports">
        {monitoringReports.length === 0 ? (
          <p>No monitoring reports found.</p>
        ) : (
          monitoringReports.map((report) => (
            <div key={report.id} className="monitoring-report-card">
              <h2>{report.cropName}</h2>
              <p>{report.description}</p>
              <p><strong>Stage:</strong> {report.stage}</p>
              <p><strong>Land Size:</strong> {report.landSize} acres</p>
              <div className="report-images">
                {report.pictures && report.pictures.map((pic, index) => (
                  <img key={index} src={`https://rafikipaypayfarmersystem.co.ke/uploads/${pic}`} alt={`Monitor ${index}`} />
                ))}
              </div>
              <button onClick={() => handleDelete(report.id)} className="delete-btn">Delete</button>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default History;