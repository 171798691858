import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Training.css';

function Training() {
  const [trainingSessions, setTrainingSessions] = useState([]);

  useEffect(() => {
    const fetchTrainingSessions = async () => {
      try {
        const response = await axios.get('https://rafikipaypayfarmersystem.co.ke/api/trainings');
        setTrainingSessions(response.data);
      } catch (error) {
        console.error('Error fetching training sessions:', error.response?.data?.error || error.message);
      }
    };
    fetchTrainingSessions();
  }, []);

  return (
    <div className="training-container">
      <header className="training-header">Training Sessions</header>
      <ul className="training-list">
        {trainingSessions.map((session) => (
          <li key={session.id} className="training-item">
            <div className="training-session-header">
              <h4>{session.type}</h4>
              <span>{session.date}</span>
            </div>
            <p>{session.description}</p>
            <div className="training-links">
              {session.videoUrl && <a href={session.videoUrl} target="_blank" rel="noopener noreferrer">Watch Video</a>}
              {session.pdfUrl && <a href={session.pdfUrl} target="_blank" rel="noopener noreferrer">Download PDF</a>}
            </div>
          </li>
        ))}
      </ul>
      <footer className="dashboard-footer">
        <Link to="/home" className="footer-icon">🏠</Link>
        <Link to="/monitor" className="footer-icon">📊</Link>
        <Link to="/training" className="footer-icon">📚</Link>
        <Link to="/login" className="login-icon">🔓</Link>
      </footer>
    </div>
  );
}

export default Training;