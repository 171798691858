import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './CreateMonitor.css';

function CreateMonitor() {
  const [formData, setFormData] = useState({
    cropName: '',
    description: '',
    stage: '',
    landSize: '',
    farmerIdNumber: '',
    filledBy: ''
  });
  const [pictures, setPictures] = useState([]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setPictures(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      const formDataObj = new FormData();

      // Append form data fields
      for (let key in formData) {
        formDataObj.append(key, formData[key]);
      }

      // Append files
      Array.from(pictures).forEach((file) => {
        formDataObj.append('pictures', file);
      });

      await axios.post('https://rafikipaypayfarmersystem.co.ke/api/monitors/create', formDataObj, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      navigate('/monitor'); // Redirect after successful creation
    } catch (error) {
      console.error('Error creating monitor:', error.response?.data || error.message);
      setError('Failed to create monitor. Please try again.');
    }
  };

  return (
    <div className="create-monitor-container">
      <header className="create-monitor-header">
        <Link to="/monitor" className="back-button">🔙</Link>
        <h1>Monitoring Reporting</h1>
      </header>
      <form className="create-monitor-form" onSubmit={handleSubmit}>
        <input type="text" name="cropName" placeholder="Crop Name" onChange={handleChange} className="form-input" required />
        <textarea name="description" placeholder="Description" onChange={handleChange} className="form-textarea" required></textarea>
        <input type="text" name="stage" placeholder="Stage" onChange={handleChange} className="form-input" required />
        <input type="text" name="landSize" placeholder="Land Size" onChange={handleChange} className="form-input" required />
        <input type="text" name="farmerIdNumber" placeholder="Farmer ID Number" onChange={handleChange} className="form-input" required />
        <input type="text" name="filledBy" placeholder="Filled By" onChange={handleChange} className="form-input" required />
        <input type="file" name="pictures" multiple onChange={handleFileChange} className="form-file-input" />
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="submit-btn">Create Monitoring Report</button>
      </form>
    </div>
  );
}

export default CreateMonitor;