import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminProfile.css';
import { Link } from 'react-router-dom';

function AdminProfile() {
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        const response = await axios.get('https://rafikipaypayfarmersystem.co.ke/api/admins/', { withCredentials: true });
        setAdmin(response.data.admin);
      } catch (error) {
        console.error('Error fetching admin:', error.response?.data?.error || error.message);
      }
    };
    fetchAdmin();
  }, []);

  if (!admin) {
    return <p>Loading...</p>;
  }

  return (
    <div className="admin-profile-container">
      <header className="admin-profile-header">
        <img src="admin-profile-image-url" alt="Admin" className="profile-image" />
        <h2>Profile</h2>
      </header>
      <div className="admin-profile-details">
        <p><strong>Name:</strong> {admin.name}</p>
        <p><strong>ID Number:</strong> {admin.idNumber}</p>
        <p><strong>Email:</strong> {admin.email}</p>
        <p><strong>Phone Number:</strong> {admin.phoneNumber}</p>
        <p><strong>Role:</strong> {admin.role}</p>
      </div>
      <footer className="dashboard-footer">
        <Link to="/admin-dashboard" className="footer-icon">🏠</Link>
        <Link to="/create-farmer" className="footer-icon">👤</Link>
        <Link to="/suspend-agronomist" className="footer-icon">🚫</Link>
        <Link to="/monitor" className="footer-icon">📊</Link>
        <Link to="/view-farmers" className="footer-icon">👥</Link>
        <Link to="/training" className="footer-icon">📚</Link>
        <Link to="/create-training" className="footer-icon">➕</Link>
      </footer>
    </div>
  );
}

export default AdminProfile;