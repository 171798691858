import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom'; // Import Link from react-router-dom
import './FarmerProfile.css';

function FarmerProfile() {
  const { id } = useParams(); // Fetch farmer ID from route parameters
  const [farmer, setFarmer] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFarmer = async () => {
      try {
        const response = await axios.get(`https://rafikipaypayfarmersystem.co.ke/api/farmers/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setFarmer(response.data);
      } catch (error) {
        console.error('Error fetching farmer:', error.response?.data?.error || error.message);
      }
    };
    fetchFarmer();
  }, [id]);

  const handleLogout = async () => {
    try {
      await axios.post('https://rafikipaypayfarmersystem.co.ke/api/farmers/logout', {}, {
        withCredentials: true
      });
      localStorage.removeItem('token');
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Error logging out:', error.response?.data?.error || error.message);
    }
  };

  if (!farmer) {
    return <p>Loading...</p>;
  }

  return (
    <div className="farmer-profile-container">
      <header className="farmer-profile-header">
        <img src="farmer-profile-image-url" alt="Farmer" className="profile-image" />
        <h2>Farmer Profile</h2>
      </header>
      <div className="farmer-profile-details">
        <p><strong>Name:</strong> {farmer.name}</p>
        <p><strong>ID Number:</strong> {farmer.idNumber}</p>
        <p><strong>Email:</strong> {farmer.email}</p>
        <p><strong>Phone Number:</strong> {farmer.phoneNumber}</p>
        <p><strong>Age:</strong> {farmer.age}</p>
        <p><strong>Gender:</strong> {farmer.gender}</p>
        <p><strong>Education:</strong> {farmer.farmerEducation}</p>
        <p><strong>Land Size:</strong> {farmer.landSize}</p>
        <p><strong>Ownership Type:</strong> {farmer.ownershipType}</p>
        <p><strong>Location:</strong> {farmer.village}, {farmer.subcounty}, {farmer.county}</p>
        <button onClick={handleLogout}>Logout</button>
      </div>

      {/* Footer */}
      <footer className="dashboard-footer">
        <Link to="/home" className="footer-icon">🏠</Link>
        <Link to="/monitor" className="footer-icon">📊</Link>
        <Link to="/training" className="footer-icon">📚</Link>
        <Link to="/login" className="login-icon">🔓</Link>
      </footer>
    </div>
  );
}

export default FarmerProfile;