import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminDiseases = () => {
  const [diseases, setDiseases] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    trendingArea: '',
    affectedCrops: '',
    control: '',
    contactInfo: ''
  });
  const [editing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const navigate = useNavigate();

  // Fetch diseases from the backend when the component mounts
  useEffect(() => {
    const fetchDiseases = async () => {
      try {
        const response = await axios.get('https://rafikipaypayfarmersystem.co.ke/api/diseases');
        setDiseases(response.data);
      } catch (error) {
        console.error('Error fetching diseases:', error);
      }
    };
    fetchDiseases();
  }, []);

  // Handle input changes for the form
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Handle form submission for creating or updating a disease
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editing) {
        await axios.put(`https://rafikipaypayfarmersystem.co.ke/api/diseases/${editId}`, formData);
      } else {
        await axios.post('https://rafikipaypayfarmersystem.co.ke/api/diseases', formData);
      }
      setFormData({ name: '', trendingArea: '', affectedCrops: '', control: '', contactInfo: '' });
      setEditing(false);
      setEditId(null);
      navigate(0); // Reload the page to refresh the list of diseases
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  // Set form data for editing a disease
  const handleEdit = (disease) => {
    setFormData({
      name: disease.name,
      trendingArea: disease.trendingArea,
      affectedCrops: disease.affectedCrops,
      control: disease.control,
      contactInfo: disease.contactInfo
    });
    setEditing(true);
    setEditId(disease._id || disease.id); // Adjust for different ID fields
  };

  // Handle deletion of a disease
  const handleDelete = async (id) => {
    console.log(id);  // Debugging step: Ensure that `id` is being logged correctly
    try {
      if (id) {
        await axios.delete(`https://rafikipaypayfarmersystem.co.ke/api/diseases/${id}`);
        setDiseases(diseases.filter(disease => disease._id !== id)); // Update state to remove the deleted disease
      } else {
        console.error("ID is undefined. Cannot delete disease.");
      }
    } catch (error) {
      console.error('Error deleting disease:', error);
    }
  };

  return (
    <div>
      <h2>Diseases Management</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Disease Name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="trendingArea"
          placeholder="Trending Area"
          value={formData.trendingArea}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="affectedCrops"
          placeholder="Affected Crops"
          value={formData.affectedCrops}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="control"
          placeholder="Control Measures"
          value={formData.control}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="contactInfo"
          placeholder="Contact Info"
          value={formData.contactInfo}
          onChange={handleInputChange}
          required
        />
        <button type="submit">{editing ? 'Update' : 'Create'}</button>
      </form>

      <h3>Existing Diseases</h3>
      <ul>
        {diseases.map(disease => (
          <li key={disease._id || disease.id}> {/* Adjust for different ID fields */}
            <p><strong>{disease.name}</strong></p>
            <p>Area: {disease.trendingArea}</p>
            <p>Affected Crops: {disease.affectedCrops}</p>
            <p>Control: {disease.control}</p>
            <p>Contact Info: {disease.contactInfo}</p>
            <button onClick={() => handleEdit(disease)}>Edit</button>
            <button onClick={() => handleDelete(disease._id || disease.id)}>Delete</button> {/* Adjust for different ID fields */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminDiseases;