import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Dashboard.css'; // Assuming you have a shared CSS file for dashboards

function AgronomistDashboard() {
  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowFooter(false); // User is scrolling down
      } else {
        setShowFooter(true); // User is scrolling up
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1>Welcome, Agronomist!</h1>
        <p>Manage your tasks efficiently and help farmers succeed.</p>
      </header>

      <div className="dashboard-content">
        <h2>Your Quick Actions</h2>
        <div className="action-cards">
          <div className="action-card">
            <Link to="/create-farmer" className="action-link">
              <span role="img" aria-label="Create Farmer">👤</span>
              <p>Farmer Registration</p>
            </Link>
          </div>
          <div className="action-card">
            <Link to="/register-agronomist" className="action-link">
              <span role="img" aria-label="Monitor">📊</span>
              <p>Agronomist Registration</p>
            </Link>
          </div>
          <div className="action-card">
            <Link to="/agronomist-profile" className="action-link">
              <span role="img" aria-label="Profile">👤</span>
              <p>Profile</p>
            </Link>
          </div>
        </div>
      </div>
      <footer className={`dashboard-footer ${showFooter ? '' : 'hide'}`}>
        <Link to="/create-farmer" className="footer-icon">👤</Link>
        <Link to="/monitor" className="footer-icon">📊</Link>
        <Link to="/register-agronomist" className="footer-icon">👤</Link>
        <Link to="/login" className="footer-icon">🔒</Link>
      </footer>
    </div>
  );
}

export default AgronomistDashboard;