import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Dashboard.css'; 

function AdminDashboard() {
  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowFooter(false); // User is scrolling down
      } else {
        setShowFooter(true); // User is scrolling up
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1>Welcome, Admin!</h1>
        <p>Oversee operations and manage the system effectively.</p>
      </header>

      <div className="dashboard-content">
        <h2>Administration Controls</h2>
        <div className="action-cards">
          <div className="action-card">
            <Link to="/create-farmer" className="action-link">
              <span role="img" aria-label="Create Farmer">👤</span>
              <p>Farmer Registration</p>
            </Link>
          </div>
          <div className="action-card">
            <Link to="/suspend-agronomist" className="action-link">
              <span role="img" aria-label="Suspend Agronomist">🚫</span>
              <p>Suspend Agronomist</p>
            </Link>
          </div>
          <div className="action-card">
            <Link to="/monitor" className="action-link">
              <span role="img" aria-label="Monitor">📊</span>
              <p>Monitor Reports</p>
            </Link>
          </div>
          <div className="action-card">
            <Link to="/view-farmers" className="action-link">
              <span role="img" aria-label="View Farmers">👥</span>
              <p>Farmers</p>
            </Link>
          </div>
          <div className="action-card">
            <Link to="/create-training" className="action-link">
              <span role="img" aria-label="Create Training">➕</span>
              <p>Trainings Generations</p>
            </Link>
          </div>
          <div className="action-card">
            <Link to="/admin-diseases" className="action-link">
              <span role="img" aria-label="Create Disease">🦠</span>
              <p>Diseases</p>
            </Link>
          </div>
          <div className="action-card">
            <Link to="/admin-profile" className="action-link">
              <span role="img" aria-label="Profile">👤</span>
              <p>Profile</p>
            </Link>
          </div>
          <div className="action-card">
            <Link to="/view-agronomists" className="action-link">
              <span role="img" aria-label="View Agronomist">👥</span>
              <p>Agronomists</p>
            </Link>
          </div>
          <div className="action-card">
            <Link to="/register-agronomist" className="action-link">
              <span role="img" aria-label="Register Agronomist">📝</span>
              <p>Registration Agronomist</p>
            </Link>
          </div>
        </div>
      </div>
      
      <footer className={`dashboard-footer ${showFooter ? '' : 'hide'}`}>
        <Link to="/create-farmer" className="footer-icon">👤</Link>
        <Link to="/suspend-agronomist" className="footer-icon">🚫</Link>
        <Link to="/monitor" className="footer-icon">📊</Link>
        <Link to="/admin-profile" className="footer-icon">👤</Link>
        <Link to="/view-farmers" className="footer-icon">👥</Link>
        <Link to="/create-training" className="footer-icon">➕</Link>
        <Link to="/create-disease" className="footer-icon">🦠</Link> {/* New Footer Link */}
      </footer>
    </div>
  );
}

export default AdminDashboard;