import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AgronomistRegister = () => {
  const [formData, setFormData] = useState({
    name: '',
    idNumber: '',
    email: '',
    phoneNumber: '',
    password: ''
  });

  const navigate = useNavigate(); // Initialize navigate function

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://rafikipaypayfarmersystem.co.ke/api/agronomists/register', formData, { withCredentials: true });
      console.log(response.data);
      navigate('/admin-dashboard'); // Redirect to login page
      // Optionally, display a success message or perform additional actions
    } catch (error) {
      console.error(error.response?.data || error.message);
      // Optionally, handle error and display an error message
    }
  };

  return (
    <div className="register-container">
      <h2>Agronomist Registration</h2>
      <form onSubmit={handleSubmit} className="register-form">
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="idNumber"
          placeholder="ID Number"
          value={formData.idNumber}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="phoneNumber"
          placeholder="Phone Number"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default AgronomistRegister;