import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Register.css';
import rafikiLogo from '../assets/rafiki-logo-1.jpeg';

function Register() {
  const [formData, setFormData] = useState({
    name: '',
    idNumber: '',
    email: '',
    phoneNumber: '',
    age: '',
    gender: '', // Add gender field
    farmerEducation: '',
    landSize: '',
    ownershipType: '',
    county: '',
    subcounty: '',
    village: '',
    password: '',
    farmerPassport: null,
    idPhotosFront: null,
    idPhotosBack: null,
  });

  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();

    for (const key in formData) {
      formDataObj.append(key, formData[key]);
    }

    try {
      await axios.post('https://rafikipaypayfarmersystem.co.ke/api/farmers/register', formDataObj, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      navigate('/agronomist-dashboard');
    } catch (error) {
      console.error('Registration failed:', error.response?.data?.error || error.message);
    }
  };

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  return (
    <div className="register-container">
      <h2>Welcome to RafikiPay</h2>
      <img src={rafikiLogo} alt="RafikiPay Logo" className="logo" />
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <>
            <input 
              type="text" 
              name="name" 
              placeholder="Name" 
              value={formData.name} 
              onChange={handleInputChange} 
              required 
            />
            <input 
              type="text" 
              name="idNumber" 
              placeholder="ID Number" 
              value={formData.idNumber} 
              onChange={handleInputChange} 
              required 
            />
            <input 
              type="email" 
              name="email" 
              placeholder="Email" 
              value={formData.email} 
              onChange={handleInputChange} 
              required 
            />
            <input 
              type="text" 
              name="phoneNumber" 
              placeholder="Phone Number" 
              value={formData.phoneNumber} 
              onChange={handleInputChange} 
              required 
            />
            <input 
              type="number" 
              name="age" 
              placeholder="Age" 
              value={formData.age} 
              onChange={handleInputChange} 
            />
            <select 
              name="gender" 
              value={formData.gender} 
              onChange={handleInputChange} 
              required
            >
              <option value="" disabled>Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
            <button type="button" onClick={nextStep}>Next</button>
          </>
        )}

        {step === 2 && (
          <>
            <input 
              type="text" 
              name="farmerEducation" 
              placeholder="Education" 
              value={formData.farmerEducation} 
              onChange={handleInputChange} 
            />
            <input 
              type="number" 
              name="landSize" 
              placeholder="Land Size" 
              value={formData.landSize} 
              onChange={handleInputChange} 
            />
            <input 
              type="text" 
              name="ownershipType" 
              placeholder="Ownership Type" 
              value={formData.ownershipType} 
              onChange={handleInputChange} 
            />
            <input 
              type="text" 
              name="county" 
              placeholder="County" 
              value={formData.county} 
              onChange={handleInputChange} 
            />
            <input 
              type="text" 
              name="subcounty" 
              placeholder="Sub-county" 
              value={formData.subcounty} 
              onChange={handleInputChange} 
            />
            <button type="button" onClick={prevStep}>Back</button>
            <button type="button" onClick={nextStep}>Next</button>
          </>
        )}

        {step === 3 && (
          <>
            <input 
              type="text" 
              name="village" 
              placeholder="Village" 
              value={formData.village} 
              onChange={handleInputChange} 
            />
            <input 
              type="file" 
              name="farmerPassport" 
              onChange={handleInputChange} 
              required 
            />
            <input 
              type="file" 
              name="idPhotosFront" 
              onChange={handleInputChange} 
              required 
            />
            <input 
              type="file" 
              name="idPhotosBack" 
              onChange={handleInputChange} 
              required 
            />
            <input 
              type="password" 
              name="password" 
              placeholder="Password" 
              value={formData.password} 
              onChange={handleInputChange} 
              required 
            />
            <button type="button" onClick={prevStep}>Back</button>
            <button type="submit">Register</button>
          </>
        )}
      </form>
    </div>
  );
}

export default Register;