import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './FarmerDashboard.css';
import weatherIcon from '../assets/insect.jpg';
import galleryImage1 from '../assets/plant.jpeg';
import galleryImage2 from '../assets/home.jpg';
import galleryImage3 from '../assets/account.png';
import galleryImage4 from '../assets/agent 2.jpg';

function FarmerDashboard() {
  const [weatherData, setWeatherData] = useState(null);
  const [diseases, setDiseases] = useState([]);
  const navigate = useNavigate();

  const farmerId = JSON.parse(localStorage.getItem('farmer')).id;

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await fetch('https://wttr.in/Nairobi?format=%t,%h,%w');
        const data = await response.text();
        const [temperature, humidity, wind] = data.split(',');

        setWeatherData({
          temperature: temperature.trim(),
          humidity: humidity.trim(),
          wind: wind.trim(),
        });
      } catch (error) {
        console.error('Error fetching weather data:', error);
      }
    };

    fetchWeather();
  }, []);

  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowFooter(false); // User is scrolling down
      } else {
        setShowFooter(true); // User is scrolling up
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchDiseases = async () => {
      try {
        const response = await axios.get('https://rafikipaypayfarmersystem.co.ke/api/diseases');
        setDiseases(response.data);
      } catch (error) {
        console.error('Error fetching diseases:', error);
      }
    };

    fetchDiseases();
  }, []);

  const handleProfileClick = () => {
    navigate(`/profile/${farmerId}`);
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1>Hello, {JSON.parse(localStorage.getItem('farmer')).name}!</h1>
        <p className="location">Good Morning!</p>
        <p className="location">CHAARIA</p>
      </header>

      <section className="weather-section">
        {weatherData ? (
          <>
            <div className="weather-card">
              <img src={weatherIcon} alt="Temperature" />
              <p>{weatherData.temperature}</p>
            </div>
            <div className="weather-card">
              <img src={weatherIcon} alt="Humidity" />
              <p>{weatherData.humidity}</p>
            </div>
            <div className="weather-card">
              <img src={weatherIcon} alt="Wind Speed" />
              <p>{weatherData.wind}</p>
            </div>
          </>
        ) : (
          <p>Loading weather data...</p>
        )}
      </section>

      <button className="diagnosis-button">Diagnosis Issues with Crop</button>

      <section className="gallery-section">
        <h2>Gallery</h2>
        <div className="gallery-images">
          <img src={galleryImage1} alt="Plant" />
          <img src={galleryImage2} alt="Home" />
          <img src={galleryImage3} alt="Account" />
          <img src={galleryImage4} alt="Agent" />
        </div>
      </section>

      <section className="trending-diseases-section">
        <h2>Trending Diseases</h2>
        <div className="diseases-list">
          {diseases.length > 0 ? (
            diseases.map(disease => (
              <div className="disease-item" key={disease._id}> {/* Add key prop here */}
                <div className="disease-icon">
                  <img src={galleryImage1} alt="Disease Icon" /> {/* Placeholder image */}
                </div>
                <div className="disease-info">
                  <h3>{disease.name}</h3>
                  <p>{disease.trendingArea}</p>
                  <span>{disease.affectedCrops}</span>
                </div>
                <div className="disease-actions">
                  <Link to={`/disease/${disease._id}`} className="action-link">
                    ➜
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <p>No trending diseases at the moment.</p>
          )}
        </div>
      </section>

      <section className="monitor-section">
        <h2>Monitoring Reports</h2>
        <button onClick={handleProfileClick}>View Profile</button>
      </section>

      <footer className={`dashboard-footer ${showFooter ? '' : 'hide'}`}>
        <Link to="/home" className="footer-icon">🏠</Link>
        <Link to="/monitor" className="footer-icon">📊</Link>
        <Link to="/training" className="footer-icon">📚</Link>
        <Link to="/login" className="login-icon">🔓</Link>
      </footer>
    </div>
  );
}

export default FarmerDashboard;