import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Import CSS for styling
import rafikiLogo from '../assets/rafiki-logo-1.jpeg';


const AgronomistLogin = () => {
  const [formData, setFormData] = useState({
    idNumber: '',
    password: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://rafikipaypayfarmersystem.co.ke/api/agronomists/login', formData, { withCredentials: true });
      console.log(response.data);
      navigate('/agronomist-dashboard');
    } catch (error) {
      console.error(error.response.data);
    }
  };

  return (
    <div className="login-container">
      <img src={rafikiLogo}  alt="Logo" className="logo" /> {/* Add your logo here */}
      <h2>Welcome Back</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="idNumber"
          placeholder="ID Number"
          onChange={handleChange}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          onChange={handleChange}
        />
        <button type="submit">Sign in</button>
      </form>
    </div>
  );
};

export default AgronomistLogin;